import React, { useEffect } from "react"

interface IProps {
  children?: React.ReactNode
  steps: number
  step: number;
  // setStep: (step: number) => void;
  title?: string
  nextStepRef: React.MutableRefObject<any>
  previousStepRef: React.MutableRefObject<any>
}

const MultiForm: React.FC<IProps> = (props: IProps) => {
  const [stepsCols, setStepsCols] = React.useState(`grid-cols-${props.steps || 1}`)
  const [stepClass, setStepClass] = React.useState(`col-span-${props.steps || 1}`)

  useEffect(() => {
    setStepClass(`col-span-${props.step || 1}`)
  }, [props.step])

  return (
    <React.Fragment>
      <div className="grid grid-cols-12 items-center p-6">
        <div className="col-span-4">
          {
            [1, 2, 3, 4].includes(props.step) &&
            <button className="p-2 bg-black text-white font-bold text-lg rounded"
              onClick={() => props?.previousStepRef?.current?.click()}>Previous step</button>
          }
        </div>
        <div className="col-span-4">
          <div className="text-gray-400 text-center mb-2">Set up payment processing</div>
          <div className="font-bold text-lg text-center mb-2">{props.title}</div>
        </div>
        <div className="col-span-4 text-right">
          {
            [1, 2, 3, 4].includes(props.step) &&
            <button className="p-2 bg-black text-white font-bold text-lg rounded"
              onClick={() => props?.nextStepRef?.current?.click()}>Next step</button>
          }
        </div>
      </div>
      <div className={`h-[3px] bg-gray-300 grid ${stepsCols}`}>
        <div className={`h-[3px] bg-blue-300 ${stepClass}`}>

        </div>
      </div>
      {props.children}
    </React.Fragment>
  )
}

export default MultiForm