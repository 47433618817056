import React, { useCallback, useContext, useEffect, useState } from "react"
import * as qs from 'qs';
import MultiForm from "../../../form/multi-forms/MultiForm"
import { Button, Option, Radio, Select } from "@material-tailwind/react"
import StoreController from '../../../../services/store'
import { AccountContext } from "../../../../context/account"
// import * as Balancer from 'react-wrap-balancer'

import {
  WizardProvider,
  useWizardContext,
  Steps,
  Step,
  DefaultWizardStepProps,
} from 'react-sweet-wizard';
import { Controller, useForm } from "react-hook-form"
import moment from "moment"
import axios from "axios"
import { toast, } from "react-hot-toast";

import PendingVerification from '../../../../assets/images/pending-verification.png'
import { ExclamationCircleIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import CheckCircleFillIcon from "../../../fundamentals/icons/check-circle-fill-icon";
import { navigate } from "gatsby";

interface IBaseProps {
  nextStepRef?: React.MutableRefObject<any>
  previousStepRef?: React.MutableRefObject<any>
  setStep?: (e) => void
  store?: any
  setUpdateLink?: (e) => void,
  updateLink?: string
}

const Step1 = ({
  // setTitle,
  nextStepRef,
  setStep
}: IBaseProps) => {
  return (
    <React.Fragment>
      <div className="hidden">
        <button type='button' ref={nextStepRef} onClick={() => {
          return setStep && setStep(2)
        }}></button>
      </div>
      <div className="container mx-auto">
        <div className="flex justify-center">
          <div className="mt-3">
            <div className="rounded-md border p-5">
              <div className="border-b py-3">
                <div className="flex justify-between items-center mb-2 ">
                  <div className="font-bold text-lg">
                    No monthly subscription
                  </div>
                  <div>
                    <div className="bg-green-100 text-green-900 p-1 rounded-md text-sm font-bold border border-green-300">
                      FREE
                    </div>
                  </div>
                </div>
                <div>
                  Fresha is the only subscription-free software in the beauty
                  and wellness industry!
                  <br />
                  No contract or commitments, you can cancel at any time.
                </div>
              </div>

              <div className="border-b py-3">
                <div className="flex justify-between items-center mb-2 ">
                  <div className="font-bold text-lg">No payout fees</div>
                  <div>
                    <div className="bg-green-100 text-green-900 p-1 rounded-md text-sm font-bold border border-green-300">
                      FREE
                    </div>
                  </div>
                </div>
                <div>
                  Get paid the very next day with free daily payouts, no more
                  waiting.
                </div>
              </div>

              <div className="border-b py-3">
                <div className="flex justify-between items-center mb-2 ">
                  <div className="font-bold text-lg">
                    Payment processing fee
                  </div>
                  <div>
                    <div className="bg-yellow-100 text-yellow-900 p-1 rounded-md text-sm font-bold border border-yellow-300">
                      1.29% + 26¢
                    </div>
                  </div>
                </div>
                <div>
                  One processing fee per day payment transaction across all
                  payment methods.
                </div>
              </div>

              <div className="py-3">
                <div className="text-gray-400">
                  Rate are exclusive of 10% sales TAX.
                </div>
                <div className="text-purple-200">
                  Learn more about our fees and commissions.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const Step2 = ({
  previousStepRef,
  nextStepRef,
  setStep,
  store
}: IBaseProps) => {
  return (
    <React.Fragment>
      <div className="hidden">
        <button type='button' ref={previousStepRef} onClick={() => {
          return setStep && setStep(1)
        }}></button>
        <button type='button' ref={nextStepRef} onClick={async () => {
          return setStep && setStep(3)
          // const qs = require('qs');
        }}></button>
      </div>
      <div className="container mx-auto">
        <div className="flex justify-center">
          <div>
            <div className="my-3 text-center">
              Follow our simple steps to begin processing payments with Fresha
            </div>
            <div className="mt-3 border rounded-md grid grid-cols-12 pr-8 py-2 gap-y-5 gap-x-4 max-w-[700px]">
              <div className="flex justify-end">
                <div className="bg-purple-400 p-3 rounded-full flex justify-center items-center text-white w-4 h-4 font-bold">
                  1
                </div>
              </div>
              <div className="col-span-11">
                <div className="font-bold text-lg ">
                  Set up your billing details
                </div>
                <div className="text-justify">
                  Get ready to use Fresha's paid features by entering your
                  credit card and billing information
                </div>
              </div>

              <div className="flex justify-end">
                <div className="bg-purple-400 p-3 rounded-full flex justify-center items-center text-white w-4 h-4 font-bold">
                  2
                </div>
              </div>
              <div className="col-span-11">
                <div className="font-bold text-lg ">
                  Enable payouts to your bank account
                </div>
                <div className="text-justify">
                  Enter your bank account details and account holder
                  information. Our team will quickly verify your details before
                  we begin to payouts funds directly to you each day.
                </div>
              </div>

              <div className="flex justify-end">
                <div className="bg-purple-400 p-3 rounded-full flex justify-center items-center text-white w-4 h-4 font-bold">
                  3
                </div>
              </div>
              <div className="col-span-11">
                <div className="font-bold text-lg ">
                  Choose how clients pay for appointments
                </div>
                <div className="text-justify">
                  Decide if you want clients to pay the full or partial amount
                  of the appointment price at the time of booking. You can also
                  choose to charge your clients for no-shows and late
                  collections.
                </div>
              </div>

              <div className="flex justify-end">
                <div className="bg-purple-400 p-3 rounded-full flex justify-center items-center text-white w-4 h-4 font-bold">
                  4
                </div>
              </div>
              <div className="col-span-11">
                <div className="font-bold text-lg ">
                  Start collecting payments from clients
                </div>
                <div className="text-justify">
                  You can begin collecting payments at checkout by charging
                  saved credit cards, sending secure payment link via text
                  sharing QR code. You can also order an in-store payment
                  terminal service.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const Step3 = ({
  previousStepRef,
  nextStepRef,
  setStep,
  setUpdateLink
}: IBaseProps) => {
  const [mccs, setMccs] = useState<any[]>([])
  const [updating, setUpdating] = useState<boolean>(false)
  const { store, } = useContext(AccountContext)

  const { handleSubmit, control, register, formState: { errors }, watch } = useForm<{
    mcc: string,
    routingNumber: string,
    accountNumber: string
  }>({
  })

  const getMccs = async () => {
    await StoreController.getMcc()
      .then((e: any) => {
        setMccs(e?.data || [])
      })
  }

  const update = async (value: any) => {
    toast.custom((t) => (
      <div
        className={`${t.visible ? 'animate-enter' : 'animate-leave'
          } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
      >
        <div className="p-4">
          <div className="mx-auto">
            Are you sure about {store?.mcc.name} is suitable for your business?
          </div>
          <div>
            <button
              onClick={async () => {
                toast.loading('Please wait in several minutes. Dont reload page')
                let result: any = null;
                try {
                  if (!store.stripeId)
                    result = await StoreController.createAccount(
                      store?.id,
                      value.routingNumber,
                      value.accountNumber,
                      `${window.location.origin}${window.location.pathname}`,
                      `${window.location.origin}${window.location.pathname}`,
                    )

                  else {
                    result = await StoreController.genUpdateLink(
                      store?.id,
                      `${window.location.origin}${window.location.pathname}`,
                      `${window.location.origin}${window.location.pathname}`
                    )
                  }
                  if (result.status !== 1) {
                    return toast.error(result.message)
                  }
                  window.location.href = result.result

                }
                finally {
                  toast.dismiss()
                }
              }}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-green-600 hover:text-green-500 focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              Yes
            </button>
            <button
              onClick={() => toast.dismiss(t.id)}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-red-600 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    ))

  }

  useEffect(() => {
    getMccs()
  }, [])


  return (
    <React.Fragment>
      <div className="hidden">
        <button type='button' ref={previousStepRef} onClick={() => {
          return setStep && setStep(2)
        }}></button>

      </div>
      <div className="container mx-auto">
        <div className="flex justify-center">
          <div>
            <form onSubmit={handleSubmit(update)}>
              <div className="mt-3 border rounded-md grid grid-cols-12 p-8 py-4 gap-y-5 gap-x-4 max-w-[700px] min-w-[700px] gap-2">
                {/* <div className="col-span-12">
                  <div className="flex gap-10 justify-center">
                    <Controller
                      name='type'
                      control={control}
                      render={
                        ({ field: { value, onChange } }) => {
                          return <Radio onChange={onChange} id="individual" value='individual' name="type" label="Individual" color="purple" defaultChecked />
                        }
                      }
                    />
                    <Controller
                      name='type'
                      control={control}
                      render={
                        ({ field: { value, onChange } }) => {
                          return <Radio onChange={onChange} id="company" value='company' name="type" label="Company" color="purple" />
                        }
                      }
                    />


                  </div>
                </div> */}

                {/* <div className="col-span-12">
                  <div className="font-bold text-lg">Business name</div>
                  <div>
                    <input
                      {...register('businessName', {
                        validate: (e) => {
                          return !e ? "Please input this field" : undefined
                        }
                      })}
                      className="outline-none border p-3 w-full capitalize rounded-sm mt-1"
                      placeholder="Enter your business name"
                    />
                  </div>
                  <div className="text-red-500 text-sm">{errors.businessName?.message
                  }</div>
                </div> */}

                <div className="col-span-12">
                  <div className="font-bold text-lg">Business type</div>
                  <div>
                    <select
                      {...register('mcc', {
                      })}
                      className="outline-none border p-3 w-full capitalize rounded-sm mt-1" value={store?.mcc?.id}>
                      {
                        mccs.map((e, index) => {
                          return <option key={e.id} value={e.id}>{e.name}</option>
                        })
                      }
                    </select>
                  </div>
                </div>

                <div className="col-span-12">
                  <div className="font-bold text-lg">Routing number</div>
                  <div>
                    <input
                      {...register('routingNumber', {
                        validate: (e: string) => {
                          if (!e || e === '')
                            return 'This field is requried'
                        }
                      })}
                      placeholder="Input routing number"
                      className="outline-none border p-3 w-full capitalize rounded-sm mt-1" />
                  </div>
                  <div className="text-red-500 text-sm">
                    {errors.routingNumber?.message}
                  </div>
                </div>

                <div className="col-span-12">
                  <div className="font-bold text-lg">Account number</div>
                  <div>
                    <input
                      {...register('accountNumber', {
                        validate: (e: string) => {
                          if (!e || e === '')
                            return 'This field is requried'
                        }
                      })}
                      placeholder="Input account number"
                      className="outline-none border p-3 w-full capitalize rounded-sm mt-1" />
                  </div>
                  <div className="text-red-500 text-sm">
                    {errors.accountNumber?.message}
                  </div>
                </div>

                {/* <div className="col-span-6">
                  <div className="font-bold text-lg">First Name</div>
                  <div>
                    <input
                      {...register('firstName', {
                        validate: (e) => {
                          return !e ? "Please input this field" : undefined
                        }
                      })}
                      className="outline-none border p-3 w-full capitalize rounded-sm mt-1"
                      placeholder="Enter your first name"
                    />
                  </div>
                </div>

                <div className="col-span-6">
                  <div className="font-bold text-lg">Last Name</div>
                  <div>
                    <input
                      {...register('lastName', {
                        validate: (e) => {
                          return !e ? "Please input this field" : undefined
                        }
                      })}
                      className="outline-none border p-3 w-full capitalize rounded-sm mt-1"
                      placeholder="Enter your last name"
                    />
                  </div>
                </div> */}

                {/* <div className="col-span-4">
                  <div className="font-bold text-lg">Year</div>
                  <div>
                    <select
                      {...register('year', {
                        valueAsNumber: true,
                        validate: (e) => {
                          return !e ? "Please input this field" : undefined
                        }
                      })}
                      className="outline-none border p-3 w-full capitalize rounded-sm mt-1">
                      <option value={undefined} selected disabled>{'Select month'}</option>
                      {
                        years.map((e, index) => {
                          return <option key={index} value={e}>{e}</option>
                        })
                      }
                    </select>
                  </div>
                </div>

                <div className="col-span-4">
                  <div className="font-bold text-lg">Month</div>
                  <div>
                    <select
                      {...register('month', {
                        valueAsNumber: true,
                        validate: (e) => {
                          return !e ? "Please input this field" : undefined
                        }
                      })}
                      className="outline-none border p-3 w-full capitalize rounded-sm mt-1">
                      <option value={undefined} selected disabled>{'Select month'}</option>
                      {
                        months.map((e, index) => {
                          return <option key={index} value={e}>{e}</option>
                        })
                      }
                    </select>
                  </div>
                </div>

                <div className="col-span-4">
                  <div className="font-bold text-lg">Day</div>
                  <div>
                    <select
                      disabled={!watchYear || !watchMonth}
                      {...register('day', {
                        valueAsNumber: true,
                        validate: (e) => {
                          return !e ? "Please input this field" : undefined
                        }
                      })}
                      className="outline-none border p-3 w-full capitalize rounded-sm mt-1" >
                      <option value={undefined} selected disabled>{'Select day'}</option>
                      {
                        days.map((e, index) => {
                          return <option key={index} value={e}>{e}</option>
                        })
                      }
                    </select>

                  </div>
                </div> */}

                {/* <div className="col-span-12">
                  <div className="font-bold text-lg">Address</div>
                  <div>
                    <input
                      {...register('address', {
                        validate: (e) => {
                          return !e ? "Please input this field" : undefined
                        }
                      })}
                      className="outline-none border p-3 w-full capitalize rounded-sm mt-1"
                      placeholder="Enter your address"
                    />
                  </div>
                </div>

                <div className="col-span-12">
                  <div className="font-bold text-lg">City</div>
                  <div>
                    <input
                      {...register('city', {
                        validate: (e) => {
                          return !e ? "Please input this field" : undefined
                        }
                      })}
                      className="outline-none border p-3 w-full capitalize rounded-sm mt-1"
                      placeholder="Enter your city"
                    />
                  </div>
                </div>

                <div className="col-span-6">
                  <div className="font-bold text-lg">State</div>
                  <div>
                    <select className="outline-none border p-3 w-full capitalize rounded-sm mt-1">
                      <option disabled={true} value="">
                        --Choose and option--
                      </option>
                      <option>California</option>

                      <option>California</option>

                      <option>California</option>
                    </select>
                  </div>
                </div> */}

                {/* <div className="col-span-6">
                  <div className="font-bold text-lg">Postal Code</div>
                  <div>
                    <input
                      {...register('postCode', {
                        validate: (e) => {
                          return !e ? "Please input this field" : undefined
                        }
                      })}
                      className="outline-none border p-3 w-full capitalize rounded-sm mt-1"
                      placeholder="Enter your postcode"
                    />
                  </div>
                </div> */}

                {/* <div className="col-span-12">
                  <div className="font-bold text-lg">ABN Code</div>
                  <div>
                    <input className="outline-none border p-3 w-full capitalize rounded-sm mt-1" />
                  </div>
                </div> */}
              </div>
              <button className="hidden" ref={nextStepRef}></button>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const PaymentView: React.FC = ({
  store = null
}) => {
  const [step, setStep] = React.useState<any>(null)
  const [title, setTitle] = React.useState<string>('')
  const [updateLink, setUpdateLink] = React.useState<string>('')
  const [render, setRender] = useState(false)
  const nextStepRef = React.useRef(null)
  const previousStepRef = React.useRef(null)

  useEffect(() => {
    if (!step)
      return

    console.log(step)
    switch (+step) {
      case 1:
        return setTitle('Overview of fees')
      case 2:
        return setTitle('Get ready to set up payment processing')
      case 3:
        return setTitle('Enter billing details')
      case 5:
        return setTitle('Unverified')
      case 6:
        return setTitle('Pending')
      case 7:
        return setTitle('Verified')
    }
  }, [step])

  useEffect(() => {
    if (!store)
      return;

    setRender(true)
    // return setStep(7)
    if (!store.stripeId) {
      return setStep(1)
    }

    if (!store.account) {
      return setStep(5)
    }

    if (store?.account?.individual?.verification?.status === 'unverified' ||
      !store?.account?.individual?.verification?.status) {
      return setStep(5)
    }

    if (store?.account?.individual?.verification?.status === 'pending') {
      return setStep(6)
    }

    if (store?.account?.individual?.verification?.status === 'verified')
      return setStep(7)
  }, [store])

  return (
    <React.Fragment>
      {
        (render) && <MultiForm
          steps={7}
          step={step}
          // setStep={setStep}
          title={title}
          nextStepRef={nextStepRef}
          previousStepRef={previousStepRef}
        >
          {
            step === 1 && <Step1 nextStepRef={nextStepRef} setStep={setStep} />
          }
          {
            step === 2 && <Step2 store={store} previousStepRef={previousStepRef} nextStepRef={nextStepRef} setStep={setStep} />
          }
          {
            step === 3 && <Step3
              setUpdateLink={setUpdateLink}
              previousStepRef={previousStepRef} nextStepRef={nextStepRef} setStep={setStep} />
          }

          {
            step === 5 && <div className="flex flex-col items-center justify-center min-h-[80vh]">
              <div>
                <ExclamationCircleIcon color='red' className="w-24 h-24" />
                {/* <img src={PendingVerification} /> */}
              </div>
              <div>
                Your information is not verified
              </div>
              <div>
                <Button
                  onClick={async () => {
                    console.log()
                    toast.loading('Please wait in several minutes. Dont reload page')
                    let result: any = null;
                    try {
                      result = await StoreController.genUpdateLink(
                        store?.id,
                        `${window.location.origin}${window.location.pathname}`,
                        `${window.location.origin}${window.location.pathname}`
                      )
                      if (result.status !== 1) {
                        return toast.error(result.message)
                      }
                      window.location.href = result.result

                    }
                    finally {
                      toast.dismiss()
                    }
                  }}
                  variant="text" size="sm" className="px-0 hover:bg-transparent" >Click here</Button> to verify your information
              </div>
            </div>
          }
          {
            step === 6 && <>
              <div className="flex flex-col items-center justify-center min-h-[80vh]">
                <div>
                  <ExclamationTriangleIcon color='#e6de05' className="w-24 h-24" />
                  {/* <img src={PendingVerification} /> */}
                </div>
                <div>
                  The system in process your information
                </div>
                {/* <div>
                  <Button
                    onClick={() => {

                    }}
                    variant="text" size="sm" className="px-0 hover:bg-transparent" >Click here</Button> to verify your information
                </div> */}
              </div>
            </>
          }
          {
            step === 7 && <>
              <div className="flex flex-col items-center justify-center min-h-[80vh]">
                <div>
                  <CheckCircleFillIcon color='#06bd36' className="w-24 h-24" />
                  {/* <img src={PendingVerification} /> */}
                </div>
                <div>
                  Your information has been verified successfully
                </div>
                <div>
                  <Button onClick={() => {
                    navigate('/stores/finance')
                  }} variant="text"
                    size='sm'
                    className="text-[14px] p-0 hover:bg-transparent normal-case">
                    Click here
                  </Button>
                </div>
                {/* <div>
                  <Button
                    onClick={() => {

                    }}
                    variant="text" size="sm" className="px-0 hover:bg-transparent" >Click here</Button> to verify your information
                </div> */}
              </div></>
          }
        </MultiForm>
      }

      {/* {(
        store?.account?.individual?.verification?.status === 'unverified' ||
        !store?.account?.individual?.verification?.status) &&

      } */}
    </React.Fragment >
  )
}

export default PaymentView
