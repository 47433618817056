import { Router } from "@reach/router"
import { navigate } from "gatsby"
import React, { useContext, useEffect } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

import PaymentView from "../../components/views/dashboard/store/payments"
import { AccountContext } from "../../context/account"

const AppointmentsPage = () => {
  const { store, getStore } = useContext(AccountContext)
  useEffect(() => {
    if (!store)
      getStore()
  }, [])
  return (
    <DndProvider backend={HTML5Backend}>
      <PaymentView
        store={store}
      />
    </DndProvider>
  )
}

export default AppointmentsPage
